import React from 'react';
import { Row, Col } from 'antd';

import Styles from './course-ind.module.css';
import HeroMarriage from '../image-components/hero/heroMarriage';
import TabAbout from '../image-components/tab/tabAbout';
import TabOverview from '../image-components/tab/tabOverview';
import TabGuide from '../image-components/tab/tabGuide';

import GuideImg from '../../images/guide-marriage.png';

const CourseMarriage = () => {
  return (
    <>
      <HeroMarriage />
      <Row className={Styles.row}>
        <Col className={Styles.col_left} sm={24} md={16}>
          <div className={Styles.heading}>MARRIAGE ADVANCE</div>
          <div className={Styles.body}>
            <div className={Styles.subtitle}>
              <div>Loving our Spouse via the Love of God.</div>
              <p>
                Marriage Advance invites us into the story of marriage—God's
                purposes and intention for bringing two distinct, separated
                individuals and sewing them together into one through the power
                of his love and Spirit. In Marriage Advance, we are not
                exploring the question of "what can a spouse do for me?" but
                rather, "How can I serve and love this spouse, and how will they
                form me into Christlike faithfulness?" It is in marriage that we
                find ourselves encountering a wholly novel side of who God is
                and what his covenant love looks like.
              </p>
            </div>
          </div>
          <div className={Styles.body}>
            <div className={Styles.quote}>
              "Marriage has a unique place because it speaks of an absolute
              faithfulness, a covenant between radically different persons, male
              and female; and so it echoes the absolute covenant of God with his
              chosen, a covenant between radically different partners."
            </div>
            <div className={Styles.author}>ROWAN WILLIAMS</div>
          </div>
        </Col>
        <Col className={Styles.col_right} sm={24} md={8}>
          <TabAbout>
            <div className={Styles.modal_title}>ABOUT</div>
            <div className={Styles.modal_body}>
              <h2 className={Styles.modal_subtitle}>The Learning Outcomes</h2>
              <p className={Styles.subheading}>Advance participants will:</p>
              <ol>
                <li>
                  Understand Ekko's theology of marriage and its importance in
                  the body.
                </li>
                <li>
                  Form a biblical framework for marriage and the expectations of
                  those entering into marriage.
                </li>
                <li>
                  Develop and form individual and family vocational credos.
                </li>
              </ol>
              <h2 className={Styles.modal_subtitle}>The Big Idea</h2>
              <p className={Styles.subheading}>Cohort Based</p>
              <p>
                Ekko's Marriage Advance organizes participants to journey
                together through the entire program. In the duration of six
                weeks, participants' primary learning and practice come from
                their engagement with Scripture, teachings, readings, and
                relationships with other couples in the Body. In so doing, they
                learn largely from each other and benefit from the mentorship of
                older couples.
              </p>
              <p className={Styles.subheading}>Advisor-Directed</p>
              <p>
                Marriage Advance is paired with a guide (Bryan H. Kim) and an
                assistant who will facilitate the weekly meetings.
              </p>
              <p className={Styles.subheading}>Online & Hybrid</p>
              <p>
                Marriage Advance engages in a variety of venues that are
                EPIC—experiential, participatory, image-driven and connective.
                It does not encourage simply downloading information; instead,
                participants and advisors value relationship, the dynamic
                interaction, and experimentation, which will enrich and deepen
                its outcome. The format comprise of weekly face-to-face meetings
                with group members and online group discussions.
              </p>
            </div>
          </TabAbout>
          <TabOverview>
            <div className={Styles.modal_title}>OVERVIEW</div>
            <div className={Styles.modal_body}>
              <div className={Styles.modal_paragraph}>
                <a
                  href="https://ekkochurch.com/wp-content/uploads/Advance/Marriage/Marriage%20Advance%20Syllabus%20F2021.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <button className={Styles.btn_syllabus}>Syllabus</button>
                </a>
                <div className={Styles.modal_subtitle}>Week 0</div>
                <div className={Styles.subheading}>
                  Preparing for EKKO's Premarital/Marriage Advance
                </div>
              </div>
              <div className={Styles.modal_paragraph}>
                <div className={Styles.modal_subtitle}>Week 1</div>
                <div className={Styles.subheading}>
                  Introduction to Marriage (Pastor Bryan Kim)
                </div>
              </div>
              <div className={Styles.modal_paragraph}>
                <div className={Styles.modal_subtitle}>Week 2</div>
                <div className={Styles.subheading}>
                  Pain & Peace Cycles (Hanna Chung)
                </div>
              </div>
              <div className={Styles.modal_paragraph}>
                <div className={Styles.modal_subtitle}>Week 3</div>
                <div className={Styles.subheading}>
                  Family of Origin (Hanna Chung)
                </div>
              </div>
              <div className={Styles.modal_paragraph}>
                <div className={Styles.modal_subtitle}>Week 4</div>
                <div className={Styles.subheading}>
                  Four Steps Intervention (Hanna Chung)
                </div>
              </div>
              <div className={Styles.modal_paragraph}>
                <div className={Styles.modal_subtitle}>Week 5</div>
                <div className={Styles.subheading}>
                  Four Horsemen (Jessica Y. Lee)
                </div>
              </div>
              <div className={Styles.modal_paragraph}>
                <div className={Styles.modal_subtitle}>Week 6</div>
                <div className={Styles.subheading}>
                  Marriage Credo (Pastor Bryan Kim)
                </div>
              </div>
            </div>
          </TabOverview>
          <TabGuide>
            <div className={Styles.modal_title}>GUIDE</div>
            <div className={Styles.modal_body}>
              <img
                className={Styles.guide_img}
                src={GuideImg}
                alt="Pastor Bryan"
              />
              <div className={Styles.guide_name}>Bryan H. Kim</div>
              <div className={Styles.guide_subheading}>
                <div>Senior Pastor of Ekko Church</div>
                <div>Marriage Advance Guide</div>
              </div>
              <p>
                I love bread. I've made it my life goal to deliver fresh manna
                to folks everywhere. And like manna, when people hear my sermons
                they often respond like the Israelites did when they first
                encountered God's fresh bread: "what is this?"
              </p>
              <p>
                I love Brazilian Jiu Jitsu. It has a built in motivator that no
                other exercise has for me—a grown man violently attempting to
                choke me out. I do however engage in extremely fast paced
                walking around my neighborhood, but only when I'm feeling really
                up to it and the weather is just right.
              </p>
              <p>
                I love to read, although, if there were films made of
                theological, historical, and business books—I'd watch them all,
                preferably done by Peter Jackson. I have tons and tons of books
                in my office. I am sure I've read most of them, at least one
                chapter of each book.
              </p>
              <p>
                I love my family. I now have four kids and one wife. I often
                wonder if it were biblical times, it may have been the other way
                around but I shouldn't talk like that. Jim Gaffigan once said,
                "you know what it's like having 5 kids? Imagine you're drowning.
                And someone hands you a baby." I guess I'm drowning but we're
                having the time of our lives as we go down!
              </p>
              <p>
                I love my tribe. I have the greatest gig ever. I get to walk
                alongside some of the bravest, most inspirational folks I've
                ever met. I say brave because if you knew what they had to
                overcome in order to live their lives for Christ you too would
                admire each and every one of them. EKKO for me has become a
                dream come true. My wife will often say to me, "EKKO has made a
                pastor out of you." I agree.
              </p>
            </div>
          </TabGuide>
        </Col>
      </Row>
    </>
  );
};

export default CourseMarriage;
