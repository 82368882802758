import React from 'react';

import SEO from '../components/seo';
import Layout from '../components/layout';
import CourseMarriage from '../components/course/courseMarriage';

const Marriage = () => {
  return (
    <>
      <SEO title="Marriage" />
      <Layout>
        <CourseMarriage />
      </Layout>
    </>
  );
};

export default Marriage;
